import { Box, Dialog, DialogActions, DialogContent, AppBar, IconButton, Button, DialogContentText, DialogTitle, Fab, FormControl, InputLabel, LinearProgress, ListItem, ListItemButton, ListItemText, MenuItem, Select, TextField, Tooltip, Toolbar } from "@mui/material";
import { useEffect, useState } from "react";
import QrCodeIcon from '@mui/icons-material/QrCode';
import CloseIcon from '@mui/icons-material/Close';
import { GetData } from "../../services/GetData";
import Modificaguia from "../../components/Modificaguia";
import ModificarFleteGuia from "../../components/ModificarFleteGuia";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { PostData } from "../../services/PostData";
import { Usuario } from "../../services/Usuario";

const LiquidacionGuias = () => {
    const MySwal = withReactContent(Swal)
    const [NumLiquidacion, setNumLiquidacion] = useState(0)
    const [openImpresion, setopenImpresion] = useState(false)
    const [open, setOpen] = useState(false)
    const [Loading, setLoading] = useState(false)
    const [TipoEmpleado, setTipoEmpleado] = useState(0)
    const [TotalSinLiq, setTotalSinLiq] = useState(0)
    const [TotalLiq, setTotalLiq] = useState(0)
    const [ListaAuxiliares, setListaAuxiliares] = useState([])
    const [ListaReexpedidor, setListaReexpedidor] = useState([])
    const [ListaGuiasLiq, setListaGuiasLiq] = useState([])
    const [ListaGuias, setListaGuias] = useState([])
    const [auxiliar1, setAuxiliar1] = useState("")
    const [reexpedidor, setReexpedidor] = useState("")
    const [Persona, setPersona] = useState("")
    const [errorGuia, setErrorGuia] = useState(false)
    const [msgError, setMsgError] = useState("")
    const [guia, setGuia] = useState("")
    const [guiaSel, setGuiaSel] = useState("")
    const [OpenModFlete, setOpenModFlete] = useState(false)
    const [DatosGuia, setDatosGuia] = useState([])
    const [UrlImpresion, setUrlImpresion] = useState("");
    const usuario = Usuario();
    const numeral = "#";

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
      };

  
      const today = new Date();
      const FechaI = new Date(today.getFullYear(), today.getMonth(), 1);
      //const FechaF = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      
      const [Fecha_Inicial, setFecha_Inicial] = useState(formatDate(today))
      const [Fecha_Final, setFecha_Final] = useState(formatDate(today))

    let dollarUS = Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });

      const handleClose = () => {
        setOpen(false);
      };

    useEffect(() => {
        GetData('empleados/listar/2').then ((result) =>{
            let responseJSON = result;
            
      
            if (responseJSON.error === false){
                const listatipos = responseJSON.empleados;
                let listaselect;              
                listaselect = listatipos.map(item => <MenuItem index={item.id_empleado} value={item.id_empleado}>{item.nombre_empleado}</MenuItem>)
                setListaAuxiliares(listaselect)
            }else{
          
          }
        })
    }, [])


        
    useEffect(() => {
        GetData('empleados/listar/3').then ((result) =>{
            let responseJSON = result;
            
      
            if (responseJSON.error === false){
                const listatipos = responseJSON.empleados;
            
                let listaselect = listatipos.map(item => <MenuItem index={item.id_empleado} value={item.id_empleado}>{item.nombre_empleado}</MenuItem>)
                setListaReexpedidor(listaselect)
            }else{
          
          }
        })
    }, [])

    useEffect(() => {
        let sum = 0;
        let sumlq = 0;
        ListaGuiasLiq.forEach(g => {
            if (g.flete_pse === "0")
                sum += parseFloat(g.flete);
        });
        setTotalSinLiq(sum);

        ListaGuias.forEach(g => {
            if (parseInt(g.flete_pse) === 0)
                sumlq += parseFloat(g.flete);
        });        
        
       // localStorage.setItem('ListaGuias', JSON.stringify(ListaGuias)); //guarda temporalmente

        setTotalLiq(sumlq);

    }, [ListaGuiasLiq, ListaGuias, OpenModFlete])
    
    const CalTotalGuisLiquidar = () => {
        let sumlq = 0;
        console.log("calculando guias")
        ListaGuias.forEach(g => {
            console.log(g)
            if (g.flete_pse === "0")
                sumlq += parseFloat(g.flete);
        });
        setTotalLiq(sumlq);
    }
    
    const consultarGuias = () => {
        
        let id_empleado = auxiliar1;
        if (TipoEmpleado === 2)
            id_empleado = reexpedidor;
            setLoading(true)
            GetData('guias/listadoguiasliqui/'+TipoEmpleado+'/'+id_empleado+'/'+Fecha_Inicial+'/'+Fecha_Final).then ((result) =>{
            let responseJSON = result;
            if (responseJSON.error === false){
                const lgsc = responseJSON.datos;
                // Filtra los elementos de lgsc que no existen en ListaGuias
                const nuevosElementos = lgsc.filter((elemento) => {                    
                    return !ListaGuias.some((item) => item.num_guia === elemento.num_guia);
                });
                
                // Establece el estado de ListaGuiasLiq con los elementos filtrados
                setListaGuiasLiq(nuevosElementos);

               /*  const storedListaGuias = localStorage.getItem('ListaGuias');
                if (storedListaGuias) {
                  setListaGuias(JSON.parse(storedListaGuias));
                } */

            }else{
                   }
                   setLoading(false)        
        })

    }

    const buscarguia = () => {
        setMsgError("")    
        setErrorGuia(false)
        let id_empleado = auxiliar1;
        if (TipoEmpleado === 2)
            id_empleado = reexpedidor;

        const guiaLista = ListaGuias.find(guiaItem => guiaItem.num_guia === guia);
        
        if (guiaLista) {
            setMsgError("Guia ya fue agregada");
            setErrorGuia(true)
            return;
        }
        
        const guiaLiq = ListaGuiasLiq.find(guiaLiq => guiaLiq.num_guia === guia.trim());
        
        if (guiaLiq) {
            
            if (guiaLiq.estado === "ENTREGADA"){
                setListaGuias(prevGuias => [...prevGuias, guiaLiq]);
                setListaGuiasLiq(prevGuiasLiq => prevGuiasLiq.filter(item => item.num_guia !== guia.trim()));
                setGuia("");
                setErrorGuia(false)
                return;
            }else{
                setMsgError("La Guía no esta entregada")
                setErrorGuia(true)
                return;
            }
        }else{
            setDatosGuia([])
            GetData('guias/buscarguiliquid/'+guia.trim()+'/'+TipoEmpleado+'/'+id_empleado).then ((result) =>{        
                if (result.error === false){                    
                    const datoguia = result.guia[0];
                    setGuia("");
                    setOpen(false)
                    
                    if (datoguia.estado === "ENTREGADA"){
                        setDatosGuia(datoguia)
                        setOpenModFlete(false);
                        if (parseFloat(datoguia.flete) === 0){
                            Swal.fire({
                                title: 'Confirmar',
                                text: '¿La guia flete cero (0), quieres editar el Flete?',
                                icon: 'question',
                                showCancelButton: true,
                                confirmButtonText: 'Sí',
                                cancelButtonText: 'No',
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    // Aquí puedes ejecutar la función de editar

                                    setOpenModFlete(true);
                                }
                            });
                        }else{   //lo agrega despues de todas las validaciones anteriores          
                            if (parseInt(datoguia.id_liquidacion) === 0){
                                Swal.fire({
                                    title: 'Confirmar',
                                    text: '¿La guia no esta en la lista pero si existe, no ha sido liquidada, desea agregarla?',
                                    icon: 'question',
                                    showCancelButton: true,
                                    confirmButtonText: 'Sí',
                                    cancelButtonText: 'No',
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        // Aquí puedes ejecutar la función de editar
                                        agregarGuiaNoExistente(datoguia.num_guia, datoguia.flete, datoguia.destinatario, datoguia.flete_pse);
                                    }
                                });   
                            }else{
                                setMsgError("La Guía ya esta liquidada")
                                setErrorGuia(true)
                                return;
                            }
                        }
                    }else{
                        MySwal.fire({
                            title: 'Buscar Guía',
                            text: "Error - La guia no esta entregada",
                            icon: 'error'
                            });
                            return;
                    }
                }else{
                    setMsgError("Guia no encontrada, no corresponde")
                    setErrorGuia(true)
                }
            })
            
        }        
    }
    
    const delete_guia = (numguia) => {
        const dguia = ListaGuias.find(guiab => guiab.num_guia === numguia.trim());
        if (dguia) {
            setListaGuiasLiq(prevGuias => [...prevGuias, dguia]);
            setListaGuias(prevGuias => prevGuias.filter(item => item.num_guia !== numguia.trim()));
        }   
    }

    const actualizarGuia = (numguia, f_pse, flete) => {
        const guiaIndex = ListaGuias.findIndex(guiab => guiab.num_guia === numguia.trim());
        if (guiaIndex !== -1) {
            const newList = [...ListaGuias];
            newList[guiaIndex].flete_pse = f_pse; // Reemplaza 'nuevoValor' con el valor deseado
            newList[guiaIndex].flete = flete; // Reemplaza 'nuevoValor' con el valor deseado
            setListaGuias(newList);
            CalTotalGuisLiquidar()
        }
    }

    const NuevaLiquidacion = () => {
        setTipoEmpleado(0)        
        setNumLiquidacion(0)        
        setListaGuiasLiq([])
        setListaGuias([])
    }

    const GuardarLiquidacion = () => {

        if (TipoEmpleado === 2 && Persona === ""){
            MySwal.fire({
                title: 'Guardar',
                text: "Error - Se debe digitar el Nombre de la persona",
                icon: 'error'
                });
                return;
        }

        if (ListaGuias.length === 0 ){
            MySwal.fire({
                title: 'Guardar',
                text: "Error - Se debe las guias a liquidar",
                icon: 'error'
                });
                return;
        }        

        let idempleado = auxiliar1;
        if (TipoEmpleado === 2)
            idempleado = reexpedidor;

        const lisguias = ListaGuias.map(guia => guia.num_guia).join(',');
        
        const data = {
            total: TotalLiq,
            id_empleado: idempleado,
            nom_persona: Persona,
            id_usuario: usuario.id_usuario,
            guias: lisguias
        }
        setLoading(true);
        PostData('/liquidacion/guardar', data).then ((result) =>{
            let responseJSON = result;            
            if (responseJSON.error === false){ 
                setLoading(false)
                setNumLiquidacion(responseJSON.numliq)           
                setUrlImpresion(`https://unirexsas.com/api_unirex/index.php/impresiones/liquidardespacho/`+responseJSON.numliq)        
                MySwal.fire({
                    title: 'Liquidación',
                    text: "Liquidación guardada con exito",
                    icon: 'success'
                    });    
            }else{
                setLoading(false)
                MySwal.fire({
                    title: 'Guardar',
                    text: responseJSON.mensaje,
                    icon: 'error'
                    });
            }
        })

    }

    const handleCloseImpresion = () => {
        setopenImpresion(false);
    };

    const agregarGuiaNoExistente = (num, flete, destinatario, f_pse) => {
        const datos ={
            fecha_asignacion: "",
            flete: flete, 
            num_guia: num,
            flete_pse : f_pse, // Reemplaza 'nuevoValor' con el valor deseado
            destinatario: destinatario,
        }
        setListaGuias(prevGuias => [...prevGuias, datos]);
        CalTotalGuisLiquidar()
    }

    const Imprimir = () => {
        const newWindow = window.open('', '_blank');
        newWindow.location.href = `https://unirexsas.com/api_unirex/index.php/impresiones/liquidardespacho/${NumLiquidacion}`;
    };

    const LiquidarGuia = (guia) => {
        setGuiaSel(guia)
        setGuia(guia);
        
    }

    useEffect(() => {
        buscarguia()
    }, [guiaSel])
    

    return (
        <>
        { Loading && <div className="loading">Loading&#8230;</div> }
        <Box sx={{ '& > :not(style)': { m: 1 } }} className="btnflotante" >
                    <Fab color="primary" aria-label="add" onClick={()=>setOpen(true)} >
                        <QrCodeIcon />
                    </Fab>
                </Box>
         { Loading && <div className="loading">Loading&#8230;</div> }
            <div className="row">
              <div className="col-md-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                            <h4 className="card-title">LIQUIDACION DE GUIAS {NumLiquidacion > 0 && <span>N° {NumLiquidacion}</span>}</h4>
                            <div className='row'>
                                    <div className='col-md-2 mb-2'>
                                    <FormControl fullWidth>
                                        
                                        <TextField id="fecha_inicial" label="Fecha Inicial" 
                                            variant="outlined" 
                                            size="small" 
                                            type="date"                                                          
                                            value={Fecha_Inicial}                   
                                            onChange={event => setFecha_Inicial(event.target.value)}   
                                            />
                                    
                                    </FormControl>
                                    </div>  
                                    <div className='col-md-2 mb-2'>
                                    <FormControl fullWidth>
                                        
                                        <TextField id="fecha_final" label="Fecha Final" 
                                            variant="outlined" 
                                            size="small" 
                                            type="date"                                                        
                                            value={Fecha_Final}                                  
                                            onChange={event => setFecha_Final(event.target.value)}      
                                            />
                                    
                                    </FormControl>
                                    </div>  
                                </div> 
                            <div className='form-row mt-2'>
                                    <div className="form-group col-3">
                                        <FormControl fullWidth>
                                        <InputLabel id="select_auxiliar1">Tipo Empleado</InputLabel>
                                        <Select
                                                labelId="select_auxiliar1"
                                                id="select_auxiliar1"
                                                value={TipoEmpleado}
                                                label="Auxiliar"
                                                onChange={event => setTipoEmpleado(event.target.value)} 
                                                size="small"
                                                disabled={ListaGuiasLiq.length>0}
                                            >
                                                <MenuItem value={0}>Seleccione</MenuItem>
                                                <MenuItem value={1}>Auxiliar</MenuItem>
                                                <MenuItem value={2}>Reexpedidor</MenuItem>
                                            </Select>
                                            </FormControl>
                                    </div>
                                    {TipoEmpleado === 1 && 
                                    <div className="form-group col-3">
                                        <FormControl fullWidth>
                                        <InputLabel id="select_auxiliar1">Auxiliar</InputLabel>
                                        <Select
                                                labelId="select_auxiliar1"
                                                id="select_auxiliar1"
                                                value={auxiliar1}
                                                label="Auxiliar"
                                                onChange={event => setAuxiliar1(event.target.value)} 
                                                size="small"
                                                disabled={ListaGuiasLiq.length>0}
                                            >
                                                {ListaAuxiliares}
                                            </Select>
                                            </FormControl>
                                    </div>
                                    }
                                    {TipoEmpleado === 2 && 
                                    <>
                                        <div className="form-group col-2">
                                            <FormControl fullWidth>
                                            <InputLabel id="select_reexpedidor">Reexpedidor</InputLabel>
                                            <Select
                                                    labelId="select_reexpedidor"
                                                    id="select_reexpedidor"
                                                    value={reexpedidor}
                                                    label="Reexpedidor"
                                                    onChange={event => setReexpedidor(event.target.value)} 
                                                    size="small"
                                                    disabled={ListaGuiasLiq.length>0}
                                                >
                                                    {ListaReexpedidor}
                                                </Select>
                                                </FormControl>
                                        </div>       
                                       
                                     </>
                                    }
                                     <div className="form-group col-2">
                                            <TextField id="persona" label="Persona que entrega" onChange={event => setPersona(event.target.value)}   variant="outlined" size="small" />
                                        </div>
                                     <div className="form-group col-4">
                                            <button type="button" className="ml-1 btn btn-info"  
                                            onClick={ () => NuevaLiquidacion()} 
                                            > Nuevo</button>

                                         {TipoEmpleado > 0 &&
                                       
                                            <button type="button" className="ml-1 btn btn-danger"  
                                            onClick={ () => consultarGuias()}  disabled={ListaGuias.length > 0}
                                            > Consultar</button>
                                        
                                        } 
                                        {ListaGuias.length > 0 &&
                                        
                                            <button type="button" className="ml-1 btn btn-primary"  
                                            onClick={ () => GuardarLiquidacion()} 
                                            disabled={NumLiquidacion>0}
                                            > Guardar</button>
                                        } 
                                        {NumLiquidacion > 0 &&
                                            <button type="button" className="ml-1 btn btn-warning"  
                                            onClick={ () => Imprimir()}
                                            > Imprimir</button>
                                        }
                                        <button type="button" className="ml-1 btn btn-info"  
                                            onClick={ () => consultarGuias()} 
                                            >Actualizar</button>
                                        
                                    </div>
                            </div>
                            <div className='row'>
                                <div className="col-lg-12 grid-margin stretch-card">
                                <div className="form-group col-6">
                                    <div className="card">
                                    <div className="card-body">
                                        <div className='row'>
                                            <div className="form-group col-4">
                                                <h4 className="card-title">Guías sin liquidar</h4>                    
                                            </div>
                                            <div className="form-group col-3">
                                                <strong>N° Guías:</strong> {ListaGuiasLiq.length}                    
                                            </div>
                                            <div className="form-group col-5">
                                                <strong>T. Flete:</strong> {dollarUS.format(TotalSinLiq)}                    
                                            </div>
                                        </div>
                                        <div className="table-responsive">
                                        
                                        <table className="table100 table-hover">
                                            <thead className='table100-head'>
                                            <tr key={45454}>
                                                <th>N° Guia</th>
                                                <th>Flete</th>
                                                <th>Estado</th>
                                                <th>Población</th>
                                                <th>Destinatario</th>
                                                <th>Empresa</th>
                                            </tr>
                                            </thead>
                                            <tbody>                                                
                                                {ListaGuiasLiq.map(g => (
                                                   <tr key={g.num_guia}>
                                                        <td  style={{ padding: '3px 0' }}><a href={`/home/detalleguia/${g.num_guia}`} target="_blank">{g.num_guia}</a></td>
                                                        <td>{dollarUS.format(g.flete)}</td>
                                                        <td>{g.mun_nombre}</td>                                                       
                                                        <td style={{
                                                            color: g.estado === 'ENTREGADA' ? '#116502' : g.estado === 'EN RUTA' ? '#ff0854' : 'black',
                                                            fontWeight: (g.estado === 'ENTREGADA' || g.estado === 'EN RUTA') ? 'bold' : 'normal'
                                                            }}>
                                                                {g.estado}
                                                        </td>
                                                        <td>{g.destinatario}</td>
                                                        <td> {g.label}
                                                            {g.estado === "ENTREGADA" && 
                                                                <a href={numeral} onClick={() => LiquidarGuia(g.num_guia)} title="Agregar" >
                                                                <img src="/img/flecha.png" width={20} /></a> 
                                                            }
                                                        </td>
                                                    </tr> 
                                                ))}
                                            </tbody>
                                        </table>
                                        </div>
                                       
                                    </div>
                                    </div>
                                </div>
                                <div className="form-group col-6">
                                <div className="card">
                                    <div className="card-body">
                                    <div className='row'>
                                        <div className="form-group col-4" >
                                                <h4 className="card-title">Liquidar</h4>                    
                                            </div>
                                            <div className="form-group col-3">
                                                <strong>N° Guías:</strong> {ListaGuias.length}                    
                                            </div>
                                            <div className="form-group col-5">
                                                <strong>T. Flete:</strong> <span className="textoliq">{dollarUS.format(TotalLiq)}</span>
                                            </div>           
                                        </div>
                                        <div className="table-responsive">
                                        
                                        <table className="table100 table-hover">
                                            <thead className='table100-head'>
                                            <tr key={45454}>
                                                <th>N° Guia</th>
                                                <th>Flete</th>
                                                <th>Población</th>
                                                <th>Destinatario</th>
                                                <th></th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                               {ListaGuias.map(g => (
                                                   <tr key={`lisg_${g.num_guia}`} >
                                                        <td style={{ padding: '3px 0' }}>{g.num_guia}</td>
                                                        <td className={g.flete_pse === '1' ? "textoliq" : ''}>{dollarUS.format(g.flete)}</td>
                                                        <td>{g.mun_nombre}</td>      
                                                        <td>{g.destinatario.length > 20 ? `${g.destinatario.slice(0, 30)}...` : g.destinatario}</td>
                                                        <td style={{ padding: '3px 0' }}>
                                                            <Modificaguia num_guia={g.num_guia} actualizarGuia={actualizarGuia} />
                                                            <Tooltip title="Borrar Guía">
                                                                <a href={numeral} onClick={() => delete_guia(g.num_guia)}><i class="mdi mdi-delete-forever" aria-hidden="true"></i></a>
                                                            </Tooltip>    
                                                        </td>

                                                    </tr> 
                                                ))}
                                            </tbody>
                                        </table>
                                        </div>
                                       
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>  
                </div> {/* card */}
              </div> {/* col-md-12 */}
            </div>  {/* row */}

            <Dialog
                    fullScreen
                    open={openImpresion}
                    onClose={handleCloseImpresion}
                    
                >
                    <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleCloseImpresion}
                        aria-label="close"
                        >
                        <CloseIcon />
                        </IconButton>
                        <Button autoFocus color="inherit" onClick={handleCloseImpresion}>
                            Cerrar
                        </Button>
                    </Toolbar>
                    </AppBar>
                    <div>
                        <iframe src={UrlImpresion} className="inframemax">
                        </iframe> 
                    </div>
                </Dialog>

            <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Escanear Guía</DialogTitle>
                    <DialogContent>
 
        

                    <TextField  id="guia" label="Guía" autoFocus fullWidth autoComplete='off'
                                                    error = {errorGuia}
                                                    helperText={msgError}
                                                    variant="standard" 
                                                    defaultValue={guia}   
                                                    value={guia}   
                                                    onChange={event => setGuia(event.target.value)} 
                                                    onKeyDown={(event) => {
                                                        
                                                        if (event.key === 'Enter')
                                                            buscarguia();
                                                    }}
                                                    />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cerrar</Button>
                    </DialogActions>
                </Dialog>

            <ModificarFleteGuia DatosGuia={DatosGuia} open={OpenModFlete} setOpenModFlete={setOpenModFlete} agregarGuiaNoExistente={agregarGuiaNoExistente} />    
        </>
    )
}
export default LiquidacionGuias;